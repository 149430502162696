import styled from '@emotion/styled';
import {
  PaddedBox,
  Colors,
  FullPanel,
  Sub,
  CardBodySplit,
  SpacedSpan,
  SubHeading,
  Flag,
} from './globalStyles';

const ContactSection = styled.section`
  position: relative;
  padding: 120px 0;

  @media (min-width: 768px) {
    min-height: 120vh; // To prevent jumpiness
  }

  img {
    object-fit: cover;
    object-position: 0% 0%;
  }

  ${CardBodySplit} {
    box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.3);
  }

  ${SubHeading} {
    color: ${Colors.white};
  }

  ${Flag} {
    margin-bottom: 15px;
  }
`;

const ContactDiv = styled.div`
  position: relative;

  &:first-of-type {
    background: ${Colors.fullBlack};
    color: ${Colors.white};
    @media (min-width: 768px) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    ${Sub} {
      @media (min-width: 768px) {
        position: absolute;
        bottom: 30px;
        left: 30px;
      }
    }
  }

  &:nth-of-type(2) {
    background: ${Colors.white};
    @media (min-width: 768px) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    svg {
      margin-right: 15px;
    }
  }

  > h3 {
    margin-top: 0;
  }

  ${FullPanel} {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.white};
  }

  ${PaddedBox} {
    background: #eeeeee;
  }
`;

export { ContactSection, SpacedSpan, ContactDiv };
