import React, { Component } from 'react';
import styled from '@emotion/styled';
import {
  Container,
  CardCopy,
  Card,
  CardBody,
  BackgroundDiv,
} from '../styles/globalStyles';
import ToggleMore from '../components/toggleMore';
import HeroCharles from '../images/homepage/hero-charles';

const HeroSection = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Card} {
    margin-top: -30px;
    @media (min-width: 768px) {
      width: 666px;
    }
  }

  h3:first-of-type {
    margin-top: 0;
  }
`;

const HeroIntro = () => (
  <div>
    <h3>
      Hello, I'm Vinay Raghu{' '}
      <span role="img" aria-label="Pray emoji">
        🙏
      </span>
    </h3>
    <CardCopy>
      I'm a software engineer who loves solving complex problems through design
      and code. I have been developing websites since 2000 (remember
      geocities?).
    </CardCopy>
    <CardCopy>
      I write <s>Javascript</s> Typescript all day and manage a team of
      engineers. I'm passionate about user experience.
    </CardCopy>
  </div>
);

const HeroMore = () => (
  <div>
    <CardCopy>
      I host a UX podcast and organize Boston JS and UX Boston meetup groups. I
      have a video course with O'Reilly on UI Animation. I give talks on
      Javascript and have written technical articles for several publications.
    </CardCopy>
    <CardCopy>
      I enjoy traveling, building local communities and spending time with my
      family.
    </CardCopy>
  </div>
);

class Hero extends Component {
  render() {
    return (
      <HeroSection>
        <BackgroundDiv>
          <HeroCharles />
        </BackgroundDiv>
        <Container>
          <Card>
            <CardBody>
              <ToggleMore content={<HeroIntro />} extra={<HeroMore />} />
            </CardBody>
          </Card>
        </Container>
      </HeroSection>
    );
  }
}

export default Hero;
