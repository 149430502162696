import React from 'react';
import { SpacedSpan, Flag } from '../styles/globalStyles';

const FlagTiles = ({
  title,
  icon,
  hover,
  selected,
  callback,
  copy = null,
  children,
}) => {
  return (
    <Flag
      onClick={callback}
      className={selected && 'selected'}
      shadow={selected && true}
      hover={hover}
    >
      <div>
        <SpacedSpan>{icon}</SpacedSpan>
      </div>
      <div>
        <h4>{title}</h4>
        {copy ? <p>{copy}</p> : null}
        {children}
      </div>
    </Flag>
  );
};

export default FlagTiles;
