// Libararies
import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _sortBy from 'lodash/sortBy';
import getQuery from '../util/query';
// Components
import FlagTiles from '../components/flagTiles';
// Styles
import { TextCenter, Container, SubHeading } from '../styles/globalStyles';
import { WorkSection, WorkDisplay, WorkDetail } from '../styles/workStyles';
// Images
import { ExternalLink } from 'react-feather';
import BannerChewy from '../images/homepage/banner-chewy';
import BannerLadders from '../images/homepage/banner-ladders';
import BannerR2s from '../images/homepage/banner-r2s';
import ThumbChewy from '../images/homepage/thumb-chewy';
import ThumbLadders from '../images/homepage/thumb-ladders';
import ThumbR2s from '../images/homepage/thumb-r2s';

const titleToImageMap = {
  runway2street: BannerR2s,
  chewy: BannerChewy,
  ladders: BannerLadders,
};

const titleToIconMap = {
  runway2street: ThumbR2s,
  chewy: ThumbChewy,
  ladders: ThumbLadders,
};

const ImageComponent = ({ title }) => {
  const query = getQuery(title);
  const Image = titleToImageMap[query];
  return <Image />;
};

const IconComponent = ({ title }) => {
  const query = getQuery(title);
  const Icon = titleToIconMap[query];
  return <Icon />;
};

const WorkDetailSection = ({ companyName, companyLink, blurb }) => {
  return (
    <WorkDetail>
      {companyName ? <ImageComponent title={companyName} /> : null}
      <p>
        {blurb}{' '}
        <b>
          <a href={companyLink}>
            Visit Site <ExternalLink size={13} />
          </a>
        </b>
      </p>
      <p />
    </WorkDetail>
  );
};

class WorkTiles extends Component {
  constructor(props) {
    super(props);
    this.tileData = this.props.data.map((item) => item.node);
    this.tileData = _sortBy(this.tileData, (item) => item.rank);
    this.state = {
      selectedWorkBlurb: '',
      selectedWorkImageUrl: '',
      selectedWorkCompanyName: '',
      selectedWorkCompanyLink: '',
    };
  }
  componentDidMount() {
    const {
      blurb: { blurb },
      imageUrl,
      companyName,
      companyLink,
    } = this.tileData[0];
    this.setSelectedWork(blurb, imageUrl, companyName, companyLink);
  }
  setSelectedWork(blurb, imageUrl, companyName, companyLink) {
    this.setState({
      selectedWorkBlurb: blurb,
      selectedWorkImageUrl: imageUrl,
      selectedWorkCompanyName: companyName,
      selectedWorkCompanyLink: companyLink,
    });
  }
  render() {
    return (
      <WorkSection>
        <Container>
          <TextCenter>
            <h2>Building software since 2009</h2>
            <p>I've been fortunate to have worked in a variety of teams.</p>
          </TextCenter>
          <WorkDisplay>
            <div>
              {this.tileData.map(
                ({
                  title,
                  blurb: { blurb },
                  imageUrl,
                  companyName,
                  companyLink,
                }) => (
                  <div key={title}>
                    <FlagTiles
                      selected={
                        this.state.selectedWorkCompanyName === companyName
                      }
                      shadow
                      icon={<IconComponent title={companyName} />}
                      iconSize={45}
                      title={title}
                      callback={this.setSelectedWork.bind(
                        this,
                        blurb,
                        imageUrl,
                        companyName,
                        companyLink
                      )}
                    >
                      <SubHeading>{companyName}</SubHeading>
                    </FlagTiles>
                  </div>
                )
              )}
            </div>
            <WorkDetailSection
              imageUrl={this.state.selectedWorkImageUrl}
              companyName={this.state.selectedWorkCompanyName}
              companyLink={this.state.selectedWorkCompanyLink}
              blurb={this.state.selectedWorkBlurb}
            />
          </WorkDisplay>
        </Container>
      </WorkSection>
    );
  }
}

const WorkQuery = () => (
  <StaticQuery
    query={graphql`
      query allContentfulWorkQuery {
        allContentfulWork {
          edges {
            node {
              title
              companyName
              companyLink
              companyLogoUrl
              blurb {
                blurb
              }
              readMoreLink
              imageUrl
              tabTitle
              tabIconUrl
              rank
            }
          }
        }
      }
    `}
    render={(data) => <WorkTiles data={data.allContentfulWork.edges} />}
  />
);

export default WorkQuery;
