import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../partials/hero';
import Contact from '../partials/contact';
import WorkTiles from '../partials/work';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Hero />
    <WorkTiles />
    <Contact />
  </Layout>
);

export default IndexPage;
