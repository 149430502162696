import styled from '@emotion/styled';
import { Flag, SpacedSpan, SubHeading } from './globalStyles';

const WorkSection = styled.section`
  padding: 120px 0;
`;

const WorkDisplay = styled.article`
  display: grid;
  align-items: start;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 300px 1fr;
  }
  grid-gap: 30px;

  ${SpacedSpan} {
    width: 60px;
    margin-left: 15px;
  }

  ${Flag} {
    margin-bottom: 30px;

    img {
      border-radius: 50%;
    }

    ${SubHeading} {
      margin-bottom: 0;
    }
  }
`;

const WorkDetail = styled.section`
  @media (min-width: 768px) {
    grid-column: 2;
    grid-row: 1 / 3;
  }
`;

export { WorkSection, WorkDisplay, WorkDetail };
